<template>
  <v-dialog
    id="automation-node-configuration-modal"
    ref="automation-node-configuration-modal"
    width="700px"
    :value="showModal"
    :size="configurationModalSize"
    persistent
    hide-header
    hide-footer
    scrollable
    modal-class="automationNodeConfigurationModal"
  >
    <v-card class="popup-card overflow-hidden border-top">
      <v-card-title class="px-6 pt-4 pb-4">
        Configure Details
      </v-card-title>
      <v-divider />

      <v-card-text class="pa-6">
        <div v-if="node">
          <div class="mb-6">
            <div
              class="simple-text-button button--blue d-inline-block align-center"
              @click="showLabelInput = !showLabelInput"
            >
              {{ showLabelInput ? "Hide Label" : "Show/Edit Label" }}
              <v-icon size="15" color="#08497e" class="ml-1">
                mdi-tag
              </v-icon>
            </div>

            <CustomTextInput
              v-if="showLabelInput"
              v-model="configuration.name"
              class="mt--2 flex-grow-1"
              @change="updateManuallySetLabel"
            />
          </div>

          <validation-observer ref="configurationComponent">
            <component
              :is="configurationComponent"
              :class="{ disabledComponent: readOnly }"
              :configuration="configuration"
              :campaign="campaign"
              :segments="mappedSegments"
              @update:configuration="updateConfiguration"
            />
          </validation-observer>
        </div>
      </v-card-text>

      <v-card-actions
        class="divider-with-shadow d-flex justify-end px-6 py-2"
      >
        <div
          class="simple-text-button button--gray mx-8 d-flex align-center"
          @click="
            $emit('close');
            onClose();
          "
        >
          {{ readOnly ? 'Close' : 'Cancel' }}
        </div>
        <v-btn
          v-if="!readOnly"
          height="34px"
          class="custom-button custom-button--blue"
          width="150px"
          @click="onSave"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ActionAddDelayConfiguration from "./configuration/ActionAddDelayConfiguration";
import ActionAddContactToSegmentConfiguration from "./configuration/ActionAddContactToSegmentConfiguration";
import ActionAutoResendEmailConfiguration from "./configuration/ActionAutoResendEmailConfiguration";
import ActionEjectConfiguration from "./configuration/ActionEjectConfiguration";
import ActionSendSplitTestEmailConfiguration from "./configuration/ActionSendSplitTestEmailConfiguration";
import ActionRemoveContactFromSegmentConfiguration from "./configuration/ActionRemoveContactFromSegmentConfiguration";
import ActionSendEmailConfiguration from "./configuration/ActionSendEmailConfiguration";
import ActionUpdateFieldConfiguration from "./configuration/ActionUpdateFieldConfiguration";
import ActionSendDirectMailConfiguration from "./configuration/ActionSendDirectMailConfiguration";
import ActionSendSmsConfiguration from "./configuration/ActionSendSmsConfiguration";
import ConditionCheckEmailStatusConfiguration from "./configuration/ConditionCheckEmailStatusConfiguration";
import ConditionCheckFieldConfiguration from "./configuration/ConditionCheckFieldConfiguration";
import ConditionIsInSegmentConfiguration from "./configuration/ConditionIsInSegmentConfiguration";
import TriggerSegmentConfiguration from "./configuration/TriggerSegmentConfiguration";
import CustomTextInput from "@/sharedComponents/CustomTextInput";

import datesMixin from "@/utils/datesMixin.js";
import CampaignMixin from "@/mixins/campaign-mixin";

export default {
  name: "AutomationNodeConfigurationModal",
  components: {
    ActionAddDelayConfiguration,
    ActionAddContactToSegmentConfiguration,
    ActionAutoResendEmailConfiguration,
    ActionEjectConfiguration,
    ActionSendSplitTestEmailConfiguration,
    ActionRemoveContactFromSegmentConfiguration,
    ActionSendEmailConfiguration,
    ActionUpdateFieldConfiguration,
    ActionSendDirectMailConfiguration,
    ActionSendSmsConfiguration,
    ConditionCheckEmailStatusConfiguration,
    ConditionCheckFieldConfiguration,
    ConditionIsInSegmentConfiguration,
    TriggerSegmentConfiguration,
    CustomTextInput,
  },
  mixins: [datesMixin, CampaignMixin],
  props: {
    node: {
      type: Object,
      default: () => ({}),
    },
    segments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      manuallySetLabel: false,
      configuration: {},
      configurationModalSize: "md",
      errors: [],
      showLabelInput: false,
    };
  },
  computed: {
    configurationComponent() {
      return this.node
        ? this.node.type + this.node.node_type + "Configuration"
        : "";
    },
    showModal() {
      return !!this.node;
    },
    mappedSegments() {
      return this.segments.map(segment => ({
        ...segment,
        name: `${segment.id} - ${segment.name}`,
      }));
    },
  },
  watch: {
    node: {
      immediate: true,
      handler(val) {
        if (val) {
          this.configuration = {
            ...val.config,
            nuid: val.nuid,
          };
          this.configurationModalSize = val.ui.config_size || "md";

          if (val.ui.showConfigurationLabel) {
            this.showLabelInput = true;
          }
        }
      },
    },
  },
  methods: {
    updateManuallySetLabel(val) {
      if (val) this.manuallySetLabel = true;
      else this.manuallySetLabel = false;
    },
    updateConfiguration(val) {
      if (this.manuallySetLabel) {
        this.configuration = {
          ...val,
          name: this.configuration.name,
        } 
      } else {
        this.configuration = val;
      }
    },
    onClose() {
      this.showLabelInput = false;
      this.errors = [];

      this.$emit("close");
    },
    async onSave() {
      try {
        if (!(await this.$refs.configurationComponent.validate())) {
          this.$notifier.error("Please check the form again.");
          return;
        }

        this.errors = [];

        await this.$store.dispatch("automation/configureNode", {
          config: {...this.configuration},
          nuid: this.node.nuid,
        });
        this.onClose();
      } catch (errors) {
        this.errors = errors;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  label {
    color: #42474c;
    font-size: 0.95rem;
    font-weight: 500;
    text-align: left;
  }

  legend {
    color: #42474c;
    font-size: 0.95rem;
    font-weight: 500;
    text-align: left;
  }

  .modal-body {
    padding: 1rem;
  }

  .automationNodeConfigurationModal {
    .header {
      color: #094073;
      margin-bottom: 0.5em;
    }

    .editLabel {
      margin-bottom: 1.5em;
      padding-left: 0;

      &.editing {
        margin-bottom: 0.2em;
      }

      span {
        font-size: 0.85rem;
      }
    }

    .row {
      justify-content: center;
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }

      &.buttonRow {
        button {
          align-items: center;
          background: #fbfcfc;
          border: 1px solid #dedede;
          border-radius: 8px;
          color: #094073;
          cursor: pointer;
          display: flex;
          font-weight: 500;
          font-size: 1.25rem;
          justify-content: center;
          outline: 1px solid transparent;
          padding-left: calc(2.5rem + 1px);
          padding-right: calc(2.5rem + 1px);
          padding-top: calc(0.5rem + 1px);
          padding-bottom: calc(0.5rem + 1px);

          i {
            font-size: 2.5rem;
            margin-right: 1rem;

            &.fa-list {
              border: 3px solid #094073;
              border-top: 8px solid #094073;
              font-size: 1.8rem;
              padding: 0.25rem 0.25rem 0;
            }
          }

          &.selected {
            background: #f6f6f7;
            border: 2px solid #cce7f2;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }

          &:last-child {
            margin-left: 50px;
          }
        }
      }

      p {
        color: #42474c;
        margin-bottom: 0;
        text-align: left;
      }
    }
  }
}

.disabledComponent {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.7;
}
</style>
